<template>
         <div class="d-block d-lg-none">
    <div class="footer fixed-bottom">
      <nav class="nav nav-fill bottom-nav text-align text-center">
      <router-link to="/doctor/index" class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/index') }" aria-current="page" ><font-awesome-icon :icon="['fas', 'home']" /><p class="bottom-title">Home</p></router-link>
      <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/consultationdesk') }" @click="getAccess('consultation')"><font-awesome-icon :icon="['fas', 'briefcase-medical']" /><p class="bottom-title">Consultation</p></div>
  <router-link to="/doctor/community-groups" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/doctor/community-groups') }" ><font-awesome-icon :icon="['fas', 'user-friends']" /><p class="bottom-title">Groups</p></router-link>              
  <div v-if=" doctorData?.customer "  class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/manage') }" @click="getAccess('manage')">
          <img :src="doctorData?.customer?.logo " alt="Customer Logo" style="width: 35px; height: 35px;" class="favicon-class" />
          <p class="bottom-title">Manage</p>
        </div>
        <div v-else  class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/clinic/manage') }" @click="getAccess('clinic-manage')">
          <font-awesome-icon :icon="['fas', 'clinic-medical']" />
          <p class="bottom-title">Manage</p>
        </div>

        <div class="nav-link bottom-link footer-image-aftersignup" @click="toggleMoreMenu">
          <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
          <p class="bottom-title">More</p>
        </div>
      
     <!-- <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/invite') }" @click="getAccess('invite')"><font-awesome-icon :icon="['fas','user-plus']" /><p class="bottom-title">Invite</p></div> -->
    </nav>
    <nav v-if="showMoreMenu" class="nav nav-fill  text-align text-center additional-items">
      <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/report') }" @click="getAccess('report')" ><font-awesome-icon :icon="['fas', 'file-signature']" /><p class="bottom-title">Reports</p></div>
      <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/library') }" @click="getAccess('library')" ><font-awesome-icon :icon="['fas', 'book-medical']" /><p class="bottom-title">Library</p></div> 
      </nav>
</div>
</div>
    </template>
    <script>
    import axios from "axios";
    export default {
        data() {
        return {
          doctorData:{},
          showMoreMenu: false, 
        }},
        created: function () {
          let DoctorUserId = localStorage.getItem("id");
          this.DoctorId = JSON.parse(DoctorUserId);
           if(this.DoctorId){
      this.getDoctors();
     } 

      },
        methods: {
          toggleMoreMenu() {
      this.showMoreMenu = !this.showMoreMenu;
    },
      currentPath(routePath) {
          this.$router.push(routePath);
        },
        isRouteActive(routePath) {
          return this.$route.path === routePath;
        },
        async getDoctors(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
        .then((response) => {
          this.doctorData = response.data
        });
    },
        async getAccess(getvalues) {
          const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
          axios
            .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
            .then((response) => {
              if(response?.data){
                 if (response.data?.status === null) {
                  this.$swal("Please complete the About Me section of the Settings Page");
                }
                else if (response.data?.status?.status === "UnderReview") {
                  this.$swal("Your request for access is under review. Please wait.");
                }
                else if(response.data?.status?.status === "Approved"){
                  if(getvalues === 'consultation'){
                    window.scrollTo(0, 0);
                    this.$router.push('/doctor/consultationdesk');
                  }
                  else if(getvalues==='report'){
                    window.scrollTo(0, 0);
                    this.$router.push( '/doctor/report');
                  }
                  else if(getvalues==='library'){
                    window.scrollTo(0, 0);
                    this.$router.push('/doctor/library');
                  }
                  else if(getvalues === 'manage'){
                    window.scrollTo(0, 0);
                    this.$router.push('/doctor/manage');
                  }
                  else if(getvalues === 'clinic-manage'){
                    window.scrollTo(0, 0);
                    this.$router.push('/doctor/clinic/manage');
                  }
                }
                else if (response.data?.status?.status === "Rejected") {
                  this.$swal("Please fix the review comments of your request for access");
                }
              }
      
            });
        },
    
        }
    }
    </script>
    <style>
    .additional-items {
  display: grid;
    position: absolute;
    bottom: 84px;
    right: 0;
}
    .footer-image-aftersignup{
      margin: 0px !important;
      color: #000 !important;
      cursor: pointer;
      padding: 7px 14px;
    }
    .tab-activated {
      background-color:#00979e;
      color: #fff !important;
      margin: 0px;
    }
    </style>